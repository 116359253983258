/*
 * see conditions here: projects/ui/src/lib/dynamic-form-regular/services/dynamic-form-regular-error-state-matcher.service.ts
 */

input.ng-invalid.ng-dirty,
textarea.ng-invalid.ng-dirty,
select.ng-invalid.ng-dirty {
  @apply text-danger-900 ring-1 ring-inset ring-danger-300 placeholder:text-danger-300 focus:ring-2 focus:ring-inset focus:ring-danger-500 #{!important};
}

[data-custom-input].ng-invalid.ng-dirty {
  input,
  textarea,
  select {
    @apply text-danger-900 ring-1 ring-inset ring-danger-300 placeholder:text-danger-300 focus:ring-2 focus:ring-inset focus:ring-danger-500 #{!important};
  }
}

.ng-invalid.ng-submitted {
  input.ng-invalid,
  textarea.ng-invalid,
  select.ng-invalid {
    @apply text-danger-900 ring-1 ring-inset ring-danger-300 placeholder:text-danger-300 focus:ring-2 focus:ring-inset focus:ring-danger-500 #{!important};
  }

  [data-custom-input].ng-invalid.ng-dirty {
    input,
    textarea,
    select {
      @apply text-danger-900 ring-1 ring-inset ring-danger-300 placeholder:text-danger-300 focus:ring-2 focus:ring-inset focus:ring-danger-500 #{!important};
    }
  }
}

ng-select.ng-dirty.ng-invalid {
  .ng-select-container {
    @apply text-danger-900 ring-1 ring-inset ring-danger-300 placeholder:text-danger-300 focus:ring-2 focus:ring-inset focus:ring-danger-500 #{!important};
  }
}
