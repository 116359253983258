.cdk-overlay-pane {
  &.modal-xs {
    max-width: calc(100vw - theme('width.4') - theme('width.4'));
    width: theme('width.96');
  }

  &.modal-sm {
    max-width: calc(100vw - theme('width.4') - theme('width.4'));
    width: theme('screens.sm');
  }

  &.modal-md {
    max-width: calc(100vw - theme('width.4') - theme('width.4'));
    width: theme('screens.md');
  }

  &.modal-lg {
    max-width: calc(100vw - theme('width.4') - theme('width.4'));
    width: theme('screens.lg');
  }

  &.modal-xl {
    max-width: calc(100vw - theme('width.4') - theme('width.4'));
    width: theme('screens.xl');
  }

  &.modal-2xl {
    max-width: calc(100vw - theme('width.4') - theme('width.4'));
    width: theme('screens.2xl');
  }
}
