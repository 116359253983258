@import '../../ui/src/lib/scss/styles';

.settings-header {
  @apply mb-4 text-2xl font-bold text-gray-900 dark:text-white;
}

/* This is alternative for native tailwindcss animation */
.animate-bounce-horizontal {
  animation: shake 5s;
  animation-iteration-count: 1;
}

/* backdrop blur darkening effect */
.cdk-overlay-backdrop {
  background: rgba(0, 0, 0, 0.25);
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  1% {
    transform: translateX(-3px);
  }
  2% {
    transform: translateX(5px);
  }
  3% {
    transform: translateX(-8px);
  }
  4% {
    transform: translateX(8px);
  }
  5% {
    transform: translateX(-5px);
  }
  6% {
    transform: translateX(3px);
  }
  7% {
    transform: translateX(0);
  }
}
