@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@angular/cdk/overlay-prebuilt.css';
@import 'styles/ng-select';
@import 'styles/modal';
@import 'styles/form';

// unify placeholder styles
.ng-placeholder {
  color: theme('colors.gray.400') !important;
  font-size: 14px;
}

input,
textarea {
  &::placeholder {
    color: theme('colors.gray.400') !important;
    font-size: 14px;
  }
}
